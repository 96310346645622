<template>
  <div>
    <div v-for="error_message in errors" v-if="errors.length > 0">
      <CAlert color="danger" closeButton>{{ error_message }}</CAlert>
    </div>

    <section v-if="allowed">
      <h4>Ingresos</h4>

      <b-row>
        <b-col lg="4">
          <CCard @click="goto('payments')">
            <CCardBody>
              <h3>Registro de Pagos</h3>
            </CCardBody>
          </CCard>
        </b-col>
      </b-row>

      <h4>Reportes</h4>

      <b-row>
        <b-col lg="4">
          <CCard @click="goto('monthly')">
            <CCardBody>
              <h3>Mensual</h3>
            </CCardBody>
          </CCard>
        </b-col>

        <b-col lg="4">
          <CCard @click="goto('monthly2')">
            <CCardBody>
              <h3>Mensual V2</h3>
            </CCardBody>
          </CCard>
        </b-col>

        <b-col lg="4">
          <CCard @click="goto('consolidated')">
            <CCardBody>
              <h3>Consolidado anual</h3>
            </CCardBody>
          </CCard>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
export default {
  name: "FinancialDashboard",
  data() {
    return {
      errors: [],
      allowed: false,
      owner: false
    };
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    if (this.userProfile.acceso.id == 1) this.owner = true;
    if (this.userProfile.status.id == 2) {
      this.allowed = true;
    } else {
      this.errors.push("No tienes acceso a este módulo");
    }
  },
  methods: {
    goto(to) {
      if (to == "payments") {
        this.$router.push({ path: "/financial/payments" });
      }
      if (to == "monthly") {
        this.$router.push({ path: "/financial/monthly" });
      }
      if (to == "monthly2") {
        this.$router.push({
          path: "/financial/monthly",
          query: { version: "2" }
        });
      }
      if (to == "consolidated") {
        this.$router.push({ path: "/financial/consolidated" });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
